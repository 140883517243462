<template>
  <main>
    <div id="header">
      <img
        @click="scroll('top')"
        id="logo"
        alt="Central Avenue"
        src="https://centralavenue.s3.ap-south-1.amazonaws.com/public/ca-logo.webp"
      />
      <div id="nav">
        <!-- <button class="nav-button" @click="scroll('top')">Home</button> -->
        <button class="nav-button" @click="scroll('about')">About</button>
        <button class="nav-button" @click="scroll('location')">Location</button>
        <!-- <button class="nav-button" @click="scroll('amenities')">Details</button> -->
        <button class="nav-button" @click="scroll('pp')">Payment</button>
        <button class="nav-button" @click="scroll('floor-plan')">
          Floor Plan
        </button>
        <button class="nav-button" @click="scroll('gallery')">Gallery</button>
        <button class="nav-button" @click="scroll('cd')">Contact</button>
        <button
          class="nav-button"
          @click="
            download(
              'https://centralavenue.s3.ap-south-1.amazonaws.com/tour.mp4'
            )
          "
        >
          Digitour
        </button>
        <button
          @click="
            download(
              'https://centralavenue.s3.ap-south-1.amazonaws.com/ant-ca-brochure.pdf'
            )
          "
          class="nav-button"
        >
          Brochure
        </button>
      </div>
    </div>
    <div id="about">
      <h5>About Project</h5>
      <div id="about1">
        <img
          id="strip"
          alt="ABOUT"
          src="https://centralavenue.s3.ap-south-1.amazonaws.com/public/about.webp"
        />
        <div id="about11">
          <h3>CENTRAL AVENUE</h3>
          <!-- <h4>Bespoke Fine Living</h4> -->
          <h4>Transcending Luxury</h4>
          <!-- <img
            loading="lazy"
            alt="Central Avenue"
            src="https://centralavenue.s3.ap-south-1.amazonaws.com/public/ca-logo.webp"
          /> -->
          <video
            src="https://centralavenue.s3.ap-south-1.amazonaws.com/tour.mp4#t=18"
            type="video/mp4"
            controls
          ></video>
          <!-- <iframe
            width="125"
            height="50"
            src="https://www.youtube.com/embed/CU7MsnlCOcI"
            title="Central Avenue"
            loop="1"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe> -->
        </div>
      </div>
      <div id="about2">
        <p>Project ID : RERA-GRG-791-2020</p>
        <p>
          Registration Certificate Number : RC/REP/HARERA/GGM/460/192/2021/28
        </p>
        <br />
        <p>
          Envisioned with your stature in mind, the home reflects your true
          style. Living spaces that transcend beyond the ordinary to create aura
          of finesse and liveliness, The central avenue is secured and wrapped
          in nature to make life beautiful with all your needs catered at one
          place. Celebrate your good life at central avenue while rejuvenating
          with a cool splash, working out the stress at gym, relax your body at
          spa and leisure at club house.
        </p>
        <button
          @click="
            download(
              'https://centralavenue.s3.ap-south-1.amazonaws.com/ant-ca-brochure.pdf'
            )
          "
          class="cta"
          id="brochure"
        >
          <span>Download Brochure &rarr;</span>
        </button>
      </div>
      <div id="location">
        <p>Location</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4755.907356471183!2d77.03397581562561!3d28.42888300001847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19d40ee8a619%3A0xdf788822abd5a64f!2scentral%20avenue!5e1!3m2!1sen!2sin!4v1629022410132!5m2!1sen!2sin"
          style="border: 0; width: 100%; height: 350px"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div id="amenities">
        <div class="specification">
          <h4>AMENITIES</h4>
          <img
            alt="amenities"
            src="https://centralavenue.s3.ap-south-1.amazonaws.com/public/amenities.webp"
          />
        </div>
        <div class="specification">
          <div>
            <h4>SPECIFICATION</h4>
            <img
              alt="specification"
              src="https://centralavenue.s3.ap-south-1.amazonaws.com/public/specification.webp"
            />
          </div>
          <div id="pp" class="detail-in">
            Payment Plan
            <iframe
              src="https://drive.google.com/file/d/1CjmNu0xy8sUy8ljneU_TsEoJGcf7uPN2/preview"
              allow="autoplay"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div id="floor-plan">
      <h2>Floor Plans</h2>
      <p>SITE PLAN</p>
      <p>MASTER PLAN</p>
      <p>PENTHOUSE</p>
      <p>4BHK + STUDY</p>
      <p>3 BHK, 3BHK +STUDY, 3BHK + SERVANT</p>
      <div id="fp-in">
        <div id="ig" class="ig" @click="showMultiple(0, 'images')">
          <img alt="site-plan" :src="images[0]['src']" loading="lazy" />
          <p>{{ images[0]["title"] }}</p>
        </div>
        <div id="image">
          <div class="ig" @click="showMultiple(1, 'images')">
            <img alt="master-plan" :src="images[1]['src']" loading="lazy" />
            <p>{{ images[1]["title"] }}</p>
          </div>
          <div class="ig" @click="showMultiple(2, 'images')">
            <img alt="3bhk floor-plan" :src="images[2]['src']" loading="lazy" />
            <p>{{ images[2]["title"] }}</p>
          </div>
        </div>
      </div>
      <div class="scrolling">
        <div
          class="scroll-image"
          v-for="(item, ind) in images.slice(3, images.length)"
          :key="item.title"
        >
          <div class="ig igs" @click="showMultiple(ind + 3, 'images')">
            <img :alt="item.title" :src="item.src" loading="lazy" />
          </div>
          <p>{{ images[ind + 3]["title"] }}</p>
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      loop
      scrollDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <div id="gallery">
      <h2>Gallery</h2>
      <div class="scrolling">
        <div
          class="scroll-image"
          v-for="(item, ind) in gallery"
          :key="item.title"
        >
          <div class="ig igs" @click="showMultiple(ind, 'gallery')">
            <img :alt="item.title" :src="item.src" loading="lazy" />
          </div>
          <p>{{ gallery[ind]["title"] }}</p>
        </div>
      </div>
    </div>
    <div id="cd">
      <h2>Contact Us</h2>
      <div id="contact-detail">
        <form id="contact-form1" @submit="submit">
          <p>Connect With Us</p>
          <input name="name1" type="text" placeholder="Name" required />
          <input name="email1" type="email" placeholder="Email" required />
          <div>
            <select id="country" name="country1">
              <option data-countrycode="AF" value="93">Afghanistan +93</option>
              <option data-countrycode="AL" value="355">Albania +355</option>
              <option data-countrycode="DZ" value="213">Algeria +213</option>
              <option data-countrycode="AS" value="1684">
                American samoa +1684
              </option>
              <option data-countrycode="AD" value="376">Andorra +376</option>
              <option data-countrycode="AO" value="244">Angola +244</option>
              <option data-countrycode="AI" value="1264">Anguilla +1264</option>
              <option data-countrycode="AQ" value="0">Antarctica +0</option>
              <option data-countrycode="AG" value="1268">
                Antigua and barbuda +1268
              </option>
              <option data-countrycode="AR" value="54">Argentina +54</option>
              <option data-countrycode="AM" value="374">Armenia +374</option>
              <option data-countrycode="AW" value="297">Aruba +297</option>
              <option data-countrycode="AU" value="61">Australia +61</option>
              <option data-countrycode="AT" value="43">Austria +43</option>
              <option data-countrycode="AZ" value="994">Azerbaijan +994</option>
              <option data-countrycode="BS" value="1242">Bahamas +1242</option>
              <option data-countrycode="BH" value="973">Bahrain +973</option>
              <option data-countrycode="BD" value="880">Bangladesh +880</option>
              <option data-countrycode="BB" value="1246">Barbados +1246</option>
              <option data-countrycode="BY" value="375">Belarus +375</option>
              <option data-countrycode="BE" value="32">Belgium +32</option>
              <option data-countrycode="BZ" value="501">Belize +501</option>
              <option data-countrycode="BJ" value="229">Benin +229</option>
              <option data-countrycode="BM" value="1441">Bermuda +1441</option>
              <option data-countrycode="BT" value="975">Bhutan +975</option>
              <option data-countrycode="BO" value="591">Bolivia +591</option>
              <option data-countrycode="BA" value="387">
                Bosnia and herzegovina +387
              </option>
              <option data-countrycode="BW" value="267">Botswana +267</option>
              <option data-countrycode="BV" value="0">Bouvet island +0</option>
              <option data-countrycode="BR" value="55">Brazil +55</option>
              <option data-countrycode="IO" value="246">
                British indian ocean territory +246
              </option>
              <option data-countrycode="BN" value="673">
                Brunei darussalam +673
              </option>
              <option data-countrycode="BG" value="359">Bulgaria +359</option>
              <option data-countrycode="BF" value="226">
                Burkina faso +226
              </option>
              <option data-countrycode="BI" value="257">Burundi +257</option>
              <option data-countrycode="KH" value="855">Cambodia +855</option>
              <option data-countrycode="CM" value="237">Cameroon +237</option>
              <option data-countrycode="CA" value="1">Canada +1</option>
              <option data-countrycode="CV" value="238">Cape verde +238</option>
              <option data-countrycode="KY" value="1345">
                Cayman islands +1345
              </option>
              <option data-countrycode="CF" value="236">
                Central african republic +236
              </option>
              <option data-countrycode="TD" value="235">Chad +235</option>
              <option data-countrycode="CL" value="56">Chile +56</option>
              <option data-countrycode="CN" value="86">China +86</option>
              <option data-countrycode="CX" value="61">
                Christmas island +61
              </option>
              <option data-countrycode="CC" value="672">
                Cocos keeling islands +672
              </option>
              <option data-countrycode="CO" value="57">Colombia +57</option>
              <option data-countrycode="KM" value="269">Comoros +269</option>
              <option data-countrycode="CG" value="242">Congo +242</option>
              <option data-countrycode="CD" value="242">
                Congo, the democratic republic of the +242
              </option>
              <option data-countrycode="CK" value="682">
                Cook islands +682
              </option>
              <option data-countrycode="CR" value="506">Costa rica +506</option>
              <option data-countrycode="CI" value="225">
                Cote d'ivoire +225
              </option>
              <option data-countrycode="HR" value="385">Croatia +385</option>
              <option data-countrycode="CU" value="53">Cuba +53</option>
              <option data-countrycode="CY" value="357">Cyprus +357</option>
              <option data-countrycode="CZ" value="420">
                Czech republic +420
              </option>
              <option data-countrycode="DK" value="45">Denmark +45</option>
              <option data-countrycode="DJ" value="253">Djibouti +253</option>
              <option data-countrycode="DM" value="1767">Dominica +1767</option>
              <option data-countrycode="DO" value="1809">
                Dominican republic +1809
              </option>
              <option data-countrycode="EC" value="593">Ecuador +593</option>
              <option data-countrycode="EG" value="20">Egypt +20</option>
              <option data-countrycode="SV" value="503">
                El salvador +503
              </option>
              <option data-countrycode="GQ" value="240">
                Equatorial guinea +240
              </option>
              <option data-countrycode="ER" value="291">Eritrea +291</option>
              <option data-countrycode="EE" value="372">Estonia +372</option>
              <option data-countrycode="ET" value="251">Ethiopia +251</option>
              <option data-countrycode="FK" value="500">
                Falkland islands malvinas +500
              </option>
              <option data-countrycode="FO" value="298">
                Faroe islands +298
              </option>
              <option data-countrycode="FJ" value="679">Fiji +679</option>
              <option data-countrycode="FI" value="358">Finland +358</option>
              <option data-countrycode="FR" value="33">France +33</option>
              <option data-countrycode="GF" value="594">
                French guiana +594
              </option>
              <option data-countrycode="PF" value="689">
                French polynesia +689
              </option>
              <option data-countrycode="TF" value="0">
                French southern territories +0
              </option>
              <option data-countrycode="GA" value="241">Gabon +241</option>
              <option data-countrycode="GM" value="220">Gambia +220</option>
              <option data-countrycode="GE" value="995">Georgia +995</option>
              <option data-countrycode="DE" value="49">Germany +49</option>
              <option data-countrycode="GH" value="233">Ghana +233</option>
              <option data-countrycode="GI" value="350">Gibraltar +350</option>
              <option data-countrycode="GR" value="30">Greece +30</option>
              <option data-countrycode="GL" value="299">Greenland +299</option>
              <option data-countrycode="GD" value="1473">Grenada +1473</option>
              <option data-countrycode="GP" value="590">Guadeloupe +590</option>
              <option data-countrycode="GU" value="1671">Guam +1671</option>
              <option data-countrycode="GT" value="502">Guatemala +502</option>
              <option data-countrycode="GN" value="224">Guinea +224</option>
              <option data-countrycode="GW" value="245">
                Guinea-bissau +245
              </option>
              <option data-countrycode="GY" value="592">Guyana +592</option>
              <option data-countrycode="HT" value="509">Haiti +509</option>
              <option data-countrycode="HM" value="0">
                Heard island and mcdonald islands +0
              </option>
              <option data-countrycode="VA" value="39">
                Holy see vatican city state +39
              </option>
              <option data-countrycode="HN" value="504">Honduras +504</option>
              <option data-countrycode="HK" value="852">Hong kong +852</option>
              <option data-countrycode="HU" value="36">Hungary +36</option>
              <option data-countrycode="IS" value="354">Iceland +354</option>
              <option data-countrycode="IN" value="91" selected>
                India +91
              </option>
              <option data-countrycode="ID" value="62">Indonesia +62</option>
              <option data-countrycode="IR" value="98">
                Iran, islamic republic of +98
              </option>
              <option data-countrycode="IQ" value="964">Iraq +964</option>
              <option data-countrycode="IE" value="353">Ireland +353</option>
              <option data-countrycode="IL" value="972">Israel +972</option>
              <option data-countrycode="IT" value="39">Italy +39</option>
              <option data-countrycode="JM" value="1876">Jamaica +1876</option>
              <option data-countrycode="JP" value="81">Japan +81</option>
              <option data-countrycode="JO" value="962">Jordan +962</option>
              <option data-countrycode="KZ" value="7">Kazakhstan +7</option>
              <option data-countrycode="KE" value="254">Kenya +254</option>
              <option data-countrycode="KI" value="686">Kiribati +686</option>
              <option data-countrycode="KP" value="850">
                Korea, democratic people's republic of +850
              </option>
              <option data-countrycode="KR" value="82">
                Korea, republic of +82
              </option>
              <option data-countrycode="KW" value="965">Kuwait +965</option>
              <option data-countrycode="KG" value="996">Kyrgyzstan +996</option>
              <option data-countrycode="LA" value="856">
                Lao people's democratic republic +856
              </option>
              <option data-countrycode="LV" value="371">Latvia +371</option>
              <option data-countrycode="LB" value="961">Lebanon +961</option>
              <option data-countrycode="LS" value="266">Lesotho +266</option>
              <option data-countrycode="LR" value="231">Liberia +231</option>
              <option data-countrycode="LY" value="218">
                Libyan arab jamahiriya +218
              </option>
              <option data-countrycode="LI" value="423">
                Liechtenstein +423
              </option>
              <option data-countrycode="LT" value="370">Lithuania +370</option>
              <option data-countrycode="LU" value="352">Luxembourg +352</option>
              <option data-countrycode="MO" value="853">Macao +853</option>
              <option data-countrycode="MK" value="389">
                Macedonia, the former yugoslav republic of +389
              </option>
              <option data-countrycode="MG" value="261">Madagascar +261</option>
              <option data-countrycode="MW" value="265">Malawi +265</option>
              <option data-countrycode="MY" value="60">Malaysia +60</option>
              <option data-countrycode="MV" value="960">Maldives +960</option>
              <option data-countrycode="ML" value="223">Mali +223</option>
              <option data-countrycode="MT" value="356">Malta +356</option>
              <option data-countrycode="MH" value="692">
                Marshall islands +692
              </option>
              <option data-countrycode="MQ" value="596">Martinique +596</option>
              <option data-countrycode="MR" value="222">Mauritania +222</option>
              <option data-countrycode="MU" value="230">Mauritius +230</option>
              <option data-countrycode="YT" value="269">Mayotte +269</option>
              <option data-countrycode="MX" value="52">Mexico +52</option>
              <option data-countrycode="FM" value="691">
                Micronesia, federated states of +691
              </option>
              <option data-countrycode="MD" value="373">
                Moldova, republic of +373
              </option>
              <option data-countrycode="MC" value="377">Monaco +377</option>
              <option data-countrycode="MN" value="976">Mongolia +976</option>
              <option data-countrycode="MS" value="1664">
                Montserrat +1664
              </option>
              <option data-countrycode="MA" value="212">Morocco +212</option>
              <option data-countrycode="MZ" value="258">Mozambique +258</option>
              <option data-countrycode="MM" value="95">Myanmar +95</option>
              <option data-countrycode="NA" value="264">Namibia +264</option>
              <option data-countrycode="NR" value="674">Nauru +674</option>
              <option data-countrycode="NP" value="977">Nepal +977</option>
              <option data-countrycode="NL" value="31">Netherlands +31</option>
              <option data-countrycode="AN" value="599">
                Netherlands antilles +599
              </option>
              <option data-countrycode="NC" value="687">
                New caledonia +687
              </option>
              <option data-countrycode="NZ" value="64">New zealand +64</option>
              <option data-countrycode="NI" value="505">Nicaragua +505</option>
              <option data-countrycode="NE" value="227">Niger +227</option>
              <option data-countrycode="NG" value="234">Nigeria +234</option>
              <option data-countrycode="NU" value="683">Niue +683</option>
              <option data-countrycode="NF" value="672">
                Norfolk island +672
              </option>
              <option data-countrycode="MP" value="1670">
                Northern mariana islands +1670
              </option>
              <option data-countrycode="NO" value="47">Norway +47</option>
              <option data-countrycode="OM" value="968">Oman +968</option>
              <option data-countrycode="PK" value="92">Pakistan +92</option>
              <option data-countrycode="PW" value="680">Palau +680</option>
              <option data-countrycode="PS" value="970">
                Palestinian territory, occupied +970
              </option>
              <option data-countrycode="PA" value="507">Panama +507</option>
              <option data-countrycode="PG" value="675">
                Papua new guinea +675
              </option>
              <option data-countrycode="PY" value="595">Paraguay +595</option>
              <option data-countrycode="PE" value="51">Peru +51</option>
              <option data-countrycode="PH" value="63">Philippines +63</option>
              <option data-countrycode="PN" value="0">Pitcairn +0</option>
              <option data-countrycode="PL" value="48">Poland +48</option>
              <option data-countrycode="PT" value="351">Portugal +351</option>
              <option data-countrycode="PR" value="1787">
                Puerto rico +1787
              </option>
              <option data-countrycode="QA" value="974">Qatar +974</option>
              <option data-countrycode="RE" value="262">Reunion +262</option>
              <option data-countrycode="RO" value="40">Romania +40</option>
              <option data-countrycode="RU" value="70">
                Russian federation +70
              </option>
              <option data-countrycode="RW" value="250">Rwanda +250</option>
              <option data-countrycode="SH" value="290">
                Saint helena +290
              </option>
              <option data-countrycode="KN" value="1869">
                Saint kitts and nevis +1869
              </option>
              <option data-countrycode="LC" value="1758">
                Saint lucia +1758
              </option>
              <option data-countrycode="PM" value="508">
                Saint pierre and miquelon +508
              </option>
              <option data-countrycode="VC" value="1784">
                Saint vincent and the grenadines +1784
              </option>
              <option data-countrycode="WS" value="684">Samoa +684</option>
              <option data-countrycode="SM" value="378">San marino +378</option>
              <option data-countrycode="ST" value="239">
                Sao tome and principe +239
              </option>
              <option data-countrycode="SA" value="966">
                Saudi arabia +966
              </option>
              <option data-countrycode="SN" value="221">Senegal +221</option>
              <option data-countrycode="CS" value="381">
                Serbia and montenegro +381
              </option>
              <option data-countrycode="SC" value="248">Seychelles +248</option>
              <option data-countrycode="SL" value="232">
                Sierra leone +232
              </option>
              <option data-countrycode="SG" value="65">Singapore +65</option>
              <option data-countrycode="SK" value="421">Slovakia +421</option>
              <option data-countrycode="SI" value="386">Slovenia +386</option>
              <option data-countrycode="SB" value="677">
                Solomon islands +677
              </option>
              <option data-countrycode="SO" value="252">Somalia +252</option>
              <option data-countrycode="ZA" value="27">South africa +27</option>
              <option data-countrycode="GS" value="0">
                South georgia and the south sandwich islands +0
              </option>
              <option data-countrycode="ES" value="34">Spain +34</option>
              <option data-countrycode="LK" value="94">Sri lanka +94</option>
              <option data-countrycode="SD" value="249">Sudan +249</option>
              <option data-countrycode="SR" value="597">Suriname +597</option>
              <option data-countrycode="SJ" value="47">
                Svalbard and jan mayen +47
              </option>
              <option data-countrycode="SZ" value="268">Swaziland +268</option>
              <option data-countrycode="SE" value="46">Sweden +46</option>
              <option data-countrycode="CH" value="41">Switzerland +41</option>
              <option data-countrycode="SY" value="963">
                Syrian arab republic +963
              </option>
              <option data-countrycode="TW" value="886">
                Taiwan, province of china +886
              </option>
              <option data-countrycode="TJ" value="992">Tajikistan +992</option>
              <option data-countrycode="TZ" value="255">
                Tanzania, united republic of +255
              </option>
              <option data-countrycode="TH" value="66">Thailand +66</option>
              <option data-countrycode="TL" value="670">
                Timor-leste +670
              </option>
              <option data-countrycode="TG" value="228">Togo +228</option>
              <option data-countrycode="TK" value="690">Tokelau +690</option>
              <option data-countrycode="TO" value="676">Tonga +676</option>
              <option data-countrycode="TT" value="1868">
                Trinidad and tobago +1868
              </option>
              <option data-countrycode="TN" value="216">Tunisia +216</option>
              <option data-countrycode="TR" value="90">Turkey +90</option>
              <option data-countrycode="TM" value="7370">
                Turkmenistan +7370
              </option>
              <option data-countrycode="TC" value="1649">
                Turks and caicos islands +1649
              </option>
              <option data-countrycode="TV" value="688">Tuvalu +688</option>
              <option data-countrycode="UG" value="256">Uganda +256</option>
              <option data-countrycode="UA" value="380">Ukraine +380</option>
              <option data-countrycode="AE" value="971">
                United arab emirates +971
              </option>
              <option data-countrycode="GB" value="44">
                United kingdom +44
              </option>
              <option data-countrycode="US" value="1">United states +1</option>
              <option data-countrycode="UM" value="1">
                United states minor outlying islands +1
              </option>
              <option data-countrycode="UY" value="598">Uruguay +598</option>
              <option data-countrycode="UZ" value="998">Uzbekistan +998</option>
              <option data-countrycode="VU" value="678">Vanuatu +678</option>
              <option data-countrycode="VE" value="58">Venezuela +58</option>
              <option data-countrycode="VN" value="84">Viet nam +84</option>
              <option data-countrycode="VG" value="1284">
                Virgin islands, british +1284
              </option>
              <option data-countrycode="VI" value="1340">
                Virgin islands, u.s. +1340
              </option>
              <option data-countrycode="WF" value="681">
                Wallis and futuna +681
              </option>
              <option data-countrycode="EH" value="212">
                Western sahara +212
              </option>
              <option data-countrycode="YE" value="967">Yemen +967</option>
              <option data-countrycode="ZM" value="260">Zambia +260</option>
              <option data-countrycode="ZW" value="263">Zimbabwe +263</option>
            </select>
            <input
              name="contact1"
              type="tel"
              placeholder="Contact Number"
              pattern="[0-9]{10}"
              required
            />
          </div>
          <div>
            <label for="tnc1">
              <input
                id="tnc1"
                name="tnc1"
                style="width: fit-content; margin-right: 8px; margin-bottom: 0"
                type="checkbox"
                checked
              />
              I agree and authorize team to contact me and/or send relevant data
              over Email, SMS & WhatsApp. This will override the registry with
              DNC / NDNC.</label
            >
          </div>
          <input type="submit" id="submit1" />
          <p id="response1"></p>
        </form>
        <div id="detail">
          <h3>Contact Information</h3>
          <div class="box">
            <div class="icon">
              <img alt="Location" src="../assets/icons/location.png" />
            </div>
            <div class="detail-text">
              <p>
                <span>Address:&nbsp;</span>
                <a
                  href="https://www.google.com/maps/place/gurugram+property/@28.512498,77.025449,3992m/data=!3m1!1e3!4m5!3m4!1s0x0:0xf0835eb94feba333!8m2!3d28.512498!4d77.0254489?hl=en"
                  target="_blank"
                  rel="noreferrer"
                  >Sector 33 sohna road, Subhash chowk gurugram - 122005</a
                >
              </p>
            </div>
          </div>
          <div class="box">
            <div class="icon">
              <img alt="Phone" src="../assets/icons/phone.svg" />
            </div>
            <div class="detail-text">
              <p>
                <span>Phone:&nbsp;</span>
                <a href="tel:+917096180961" target="_blank">+91 70961-80961</a>
              </p>
            </div>
          </div>
          <div class="box">
            <div class="icon">
              <img alt="Email" src="../assets/icons/email.png" />
            </div>
            <div class="detail-text">
              <p>
                <span>Email:&nbsp;</span>
                <a
                  style="word-break: break-all"
                  href="mailto:centralavenue@gurugramproperty.in"
                  target="_blank"
                  >centralavenue@gurugramproperty.in</a
                >
              </p>
            </div>
          </div>
          <div class="box">
            <div class="icon">
              <img alt="Website" src="../assets/icons/website.png" />
            </div>
            <div class="detail-text">
              <p>
                <span>Website:&nbsp;</span>
                <a
                  href="https://gurugramproperty.in/project-details/Central-Avenue?id=611628311e6e50c7c934a8a0"
                  target="_blank"
                  >gurugramproperty.in</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <p id="end">&copy; Central Avenue. All rights reserved.</p>
    </div>
  </main>
</template>

<script>
import Axios from "axios";
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "Header",
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      imgs: [],
      images: [
        {
          title: "Site Plan",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/image/siteplan.webp",
        },
        {
          title: "Master Plan",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/image/masterplan.webp",
        },
        {
          title: "Penthouse Upper",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/image/penthouse-upper.webp",
        },
        {
          title: "Penthouse Lower",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/image/penthouse-lower.webp",
        },
        {
          title: "4 BHK + Study",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/image/4bhk%2Bstudy.webp",
        },
        {
          title: "3 BHK",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/image/3bhk.webp",
        },
        {
          title: "3 BHK + Servant",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/image/3bhk%2Bservant.webp",
        },
        {
          title: "3 BHK + Study (1165 SF)",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/image/3bhk%2Bstudy-1165.webp",
        },
        {
          title: "3 BHK + Study (1210 SF)",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/image/3bhk%2Bstudy-1210.webp",
        },
      ],
      gallery: [
        {
          title: "Original View",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/view-1.webp",
        },
        {
          title: "Arial View",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/view-2.webp",
        },
        {
          title: "Night View",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/view-3.webp",
        },

        {
          title: "Drawing Room",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/bed-1.webp",
        },
        {
          title: "Master Bedroom",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/bed-2.webp",
        },
        {
          title: "Smart Home",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/smart-home.webp",
        },
        {
          title: "Shop",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/shop.webp",
        },
        {
          title: "Gym",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/gym.webp",
        },
        {
          title: "Swimming pool",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/swim-2.webp",
        },
        {
          title: "Swimming pool",
          src: "https://centralavenue.s3.ap-south-1.amazonaws.com/gallery/swim-1.webp",
        },
      ],
      visible: false,
      index: 0,
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();
      var data = {
        name: document.getElementsByName("name1")[0].value,
        email: document.getElementsByName("email1")[0].value,
        country: document.getElementsByName("country1")[0].value,
        contact: document.getElementsByName("contact1")[0].value,
        tnc: document.getElementsByName("tnc1")[0].checked,
      };
      Axios.post(
        "https://nkz5jguzh2.execute-api.ap-south-1.amazonaws.com/Prod/contact",
        data
      )
        .then((response) => {
          if (response.status == 200) {
            document.getElementById("response1").innerText = response.data;
            var form = document.getElementById("contact-form1");
            form.reset();
          } else {
            alert(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Something Went Wrong.\nPlease try again.");
        });
    },
    scroll(id) {
      if (id == "top") {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      } else {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    download(url) {
      window.open(url);
    },
    showMultiple(index, lis) {
      this.index = index;
      if (lis == "images") {
        this.imgs = this.images;
      } else if (lis == "gallery") {
        this.imgs = this.gallery;
      }
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    prev() {
      if (this.index == 0) {
        this.index = this.imgs.length - 1;
      } else {
        this.index -= 1;
      }
      return this.index;
    },
    next() {
      if (this.index == this.imgs.length - 1) {
        this.index = 0;
      } else {
        this.index += 1;
      }
      return this.index;
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carter+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Electrolize&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hahmlet:wght@600&display=swap");
main {
  margin-top: max(405px, 56vh);
}
#logo {
  height: max(45px, 8vh);
  margin-right: 5vw;
  border-radius: 5px;
}
#logo:hover{
  cursor: pointer;
}
#country {
  width: 110px;
}
#header {
  width: 100vw;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  top: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: max(10px, 0.5vh) 0;
  background-image: linear-gradient(rgb(53, 53, 53) 15%, white);
  background-color: white;
  z-index: 2;
}
#nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.nav-button {
  font-size: 24px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  padding: 2vh 1.5vw 0 1.5vw;
  color: black;
  font-family: "Nanum Myeongjo", serif;
}
.nav-button:hover {
  color: white;
  cursor: pointer;
}
#about {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#about1 {
  display: flex;
}
#about11 {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh 1vw;
  height: fit-content;
  margin-top: 10vh;
}
#about11 h3 {
  font-size: 25px;
  color: #6c6a3b;
  font-family: "Electrolize", sans-serif;
  margin: 0;
}
#about11 h4 {
  font-size: 40px;
  margin: 10px 0;
  font-family: "Hahmlet", serif;
}
#about11 img,
#about11 video,
#about11 iframe {
  width: min(600px, 73vw);
  height: min(350px, 40vh);
  border: none;
  border-radius: 10px;
  margin: 0;
}
#strip {
  height: 40vh;
  max-width: 25vw;
}
#about2 {
  display: flex;
  width: 85vw;
  flex-direction: column;
  align-items: center;
  padding: 4vh 3vw;
  font-size: 20px;
}
#about h5 {
  font-size: 30px;
}
.specification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#brochure {
  align-self: flex-start;
  margin: 4vh 0 0 0;
  background-color: black;
  color: white;
  font-family: "Quicksand", sans-serif;
}
#amenities iframe {
  width: 48vw;
  min-height: 700px;
  margin-top: 20px;
  border: none;
}
.detail-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
  margin: 2vh 0;
}
#detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 90%;
}
#amenities {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#amenities h4 {
  font-size: 25px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
#amenities img {
  width: 48vw;
}
#location {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  font-weight: bold;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 50px 0;
}
#floor-plan,
#gallery {
  width: 96vw;
  padding: 13vh 2vw 0 2vw;
}
#cd {
  padding-top: 13vh;
}
#cd h2 {
  font-size: 60px;
  font-weight: bold;
  font-family: "Alfa Slab One", cursive;
  margin-left: 2vw;
  margin-bottom: 0;
}
#floor-plan h2,
#gallery h2 {
  font-size: 60px;
  font-weight: bold;
  font-family: "Alfa Slab One", cursive;
}
#fp-in {
  width: 96vw;
  padding: 2vh 0;
  display: flex;
}
#image {
  height: 50vh;
  width: 44vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
#image div {
  height: 24.8vh;
  width: 40vw;
}
#ig {
  height: 50vh;
  width: 60vw;
}
.ig {
  display: flex;
  align-items: center;
  background-color: grey;
  border-radius: 10px;
  justify-content: center;
  box-shadow: inset 10px 10px 50px black;
  cursor: pointer;
}
.ig img {
  max-width: 100%;
  max-height: 100%;
}
.ig p {
  position: absolute;
  color: white;
  font-size: 22px;
  font-weight: bold;
  background-color: black;
  opacity: 0.6;
  padding: 2vh 2vw;
}
.igs {
  height: max(160px, 18vh);
  width: max(240px, 27vh);
  margin: 10px;
  overflow: hidden;
}
.scroll-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.scrolling {
  display: flex;
  overflow: scroll;
  width: 96vw;
  background-color: rgb(221, 221, 221);
  padding: 10px 0;
  border-radius: 5px;
}
#contact-form1 {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #002b4a;
  padding: 5vh 5vw;
  width: 40vw;
  height: 100%;
}
#contact-detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 2vh 0 10vh 0vw;
  width: 100vw;
  height: 650px;
}
#contact-form1 input {
  font-size: 22px;
  color: white;
  background: transparent;
  border: none;
  border-bottom: 2px solid white;
  margin-bottom: 6vh;
  padding: 1vh 1vw;
}
#contact-form1 div {
  display: flex;
}
#contact-form1 div input {
  width: 90%;
}
#contact-form1 select {
  font-size: 22px;
  padding: 1vh 0vw;
  margin-bottom: 6vh;
  color: white;
  background: transparent;
  border: none;
  border-bottom: 2px solid white;
}
#contact-form1 input:focus {
  outline: none;
}
#contact-form1 p {
  font-size: 30px;
  color: white;
  margin-bottom: 5vh;
  align-self: center;
}
#submit1 {
  margin-top: 5vh;
  background-color: white !important;
  color: black !important;
  border-radius: 5px;
  border-bottom: none !important;
  font-family: "Quicksand", sans-serif;
}
#submit1:hover {
  cursor: pointer;
}
#detail {
  background-color: #010d1f;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5vh 2vw;
  width: 46vw;
  height: 100%;
  justify-content: center;
}
#detail h3 {
  font-size: 35px;
}
#detail p {
  font-size: 20px;
  margin: 10px 10px;
}
#detail img {
  height: 35px;
  width: 40px;
  border-radius: 300px;
  background-color: black;
  border: 1px solid white;
  margin: 1vh 1vw;
}
.box {
  display: flex;
  height: 50px;
  align-items: center;
}
.icon {
  height: auto;
}
.detail-text {
  height: fit-content;
  width: 100%;
  display: flex;
  color: white;
}
.detail-text a {
  color: white;
  margin: 1vh 0.2vw;
}
#end {
  margin-left: auto;
  width: fit-content;
  margin-right: auto;
  margin-top: 20px;
}
#response1 {
  font-size: 16px !important;
  margin: 0;
}
@media screen and (max-width: 800px) {
  #amenities iframe {
    width: 88vw;
    height: 500px;
    border: 1vw solid gray;
  }
  #amenities img {
    width: 90vw;
  }
  #about2 p {
    font-size: 16px;
  }
  #detail {
    width: 96vw;
    height: fit-content;
  }
  #detail img {
    margin: 0;
    width: 35px;
  }
  #detail h3 {
    font-size: 27px;
  }
  #detail p {
    font-size: 16px;
  }
  #contact-form1 {
    width: 100vw;
    height: fit-content;
  }
  #contact-detail {
    height: fit-content;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 700px) {
  #country {
    width: 80px;
  }
  .nav-button {
    font-size: 18px;
  }
  #about11 h3 {
    font-size: 15px;
  }
  #strip {
    height: 30vh;
    max-width: 23vw;
  }
  #about11 h4 {
    font-size: 18px;
  }
  #about11 img,
  #about11 video,
  #about11 iframe {
    width: min(300px, 73vw);
    height: min(250px, 30vh);
  }
  #about2 {
    width: 90vw;
  }
  #about2 p {
    font-size: 14px;
  }
  #amenities iframe {
    width: 98vw;
    height: 500px;
    border: 1vw solid gray;
  }
  #amenities img {
    width: 100vw;
  }
  #floor-plan h2,
  #gallery h2,
  #cd h2 {
    font-size: 40px;
  }
  #fp-in {
    width: 96vw;
    height: max(350px, 50vh);
    padding: 2vh 0;
    display: flex;
    flex-wrap: wrap;
  }
  #image {
    height: max(160px, 23vh);
    width: 96vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  #image div {
    height: max(160px, 23vh);
    width: 47vw;
  }
  #ig {
    height: max(160px, 23vh);
    width: 96vw;
  }
  .ig p {
    font-size: 16px;
  }
  #contact-form1 input {
    font-size: 18px;
  }
}
</style>
